import React, { ReactNode, useContext } from "react";
import SEO from "../components/SEO";
import Container from "@src/components/Container";
import { theme, Title, Heading, BodyText, Space, Subheading } from "@src/components/nessie-web";
import styled from "@emotion/styled";
import { Box } from "@src/components/Boxes";
import Link from "@src/components/UTMLink";
import { getRelativePath } from "@src/utils/routes";
import MojoWithDoc from "../assets/images/aboutplus/mojo-with-doc.svg";
import Signature from "../assets/images/aboutplus/signature.svg";
import { mediaQueries } from "@src/styles/theme";
import { graphql, useStaticQuery } from "gatsby";
import Translate from "@src/components/translation/Translate";
import { TranslationContext } from "@src/components/translation/TranslationContext";

const {
  colors: { dt_taro50, dt_white, dt_taro10, dt_taro90 },
  borders: { dt_border_card },
  radii: { dt_radius_m },
  shadows: { dt_shadow_shadezies },
  space: { dt_m, dt_l, dt_xxl },
} = theme;

const PrinciplesSection = styled("section")`
  margin-top: 100px;
  padding: 100px 0;
  background: ${dt_taro10};
`;
const LetterContent = styled("div")`
  font-family: proxima-nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.25px;
    margin-bottom: ${dt_m}px;
    color: ${dt_taro50};
  }
  p,
  li {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.25px;
    margin-bottom: ${dt_m}px;
    color: ${dt_taro90};
  }
`;
const PrinciplesGrid = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  grid-row: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  ${mediaQueries[1]} {
    grid-template-columns: 1fr 1fr;
    grid-row: auto;
  }
`;

const PrinciplesGridItem = styled("div")`
  display: flex;
  flex-direction: row;
  background: ${dt_white};
  border: ${dt_border_card};
  border-radius: ${dt_radius_m};
  padding: ${dt_l}px;
  &:hover {
    box-shadow: ${dt_shadow_shadezies};
  }
`;

const PrinciplesLink = ({ path, children }: { path: string; children: ReactNode }) => {
  if (path[0] === "/") {
    return (
      <Subheading color="aqua50">
        <Link to={getRelativePath(path)}>{children}</Link>
      </Subheading>
    );
  } else {
    return (
      <Subheading color="aqua50">
        <a href={path} target="_blank" rel="noreferrer">
          {children}
        </a>
      </Subheading>
    );
  }
};

// = styled(Link)`
//   font-size: 18px;
//   line-height: 22px;
//   color: ${dt_aqua50};
//   font-weight: 700;
//   &:hover {
//     text-decoration: underline;
//   }
// `;

const IndexPage = () => {
  const gqlData = useStaticQuery(graphql`
    {
      directus {
        page_about_plus {
          princples_list
        }
      }
    }
  `);

  const {
    directus: {
      page_about_plus: { princples_list },
    },
  } = gqlData;

  const { translate: t } = useContext(TranslationContext);

  return (
    <>
      <SEO
        title="About ClassDojo Plus"
        description="ClassDojo stays free because of our optional subscription service. This supports our mission to give every kid on earth an education they love."
      />
      <Container>
        <Box textAlign="center" maxWidth={500} marginX="auto" marginY={dt_xxl}>
          <Title size={2}>
            <Translate path="directus.page_about_plus.heading" />
          </Title>
          <Space size="s" />
          <Heading color="dt_taro50">
            <Translate path="directus.page_about_plus.tagline" />
          </Heading>
          <Space size="xxl" />
          <img src={MojoWithDoc} width="199" height="123" alt="Mojo holding a paper" />
        </Box>

        <Box maxWidth={750} margin="auto">
          <LetterContent>
            <Translate path="directus.page_about_plus.letter" />
            <img src={Signature} width="160" alt="Sam and Liam's signature"></img>
          </LetterContent>
        </Box>
      </Container>
      <PrinciplesSection>
        <Container>
          <Box maxWidth={750} margin="auto" textAlign="center" marginBottom={dt_xxl}>
            <Title size={1}>
              <Translate path="directus.page_about_plus.principles_heading" />
            </Title>
            <Space size="m" />
            <BodyText>
              <Translate path="directus.page_about_plus.principles_description" />
            </BodyText>
          </Box>
          <PrinciplesGrid>
            {princples_list.map((principle, index) => (
              <PrinciplesGridItem key={index}>
                <img src={principle.icon_url} width="100" height="100" alt="" />
                <Box marginLeft={18}>
                  <Heading>
                    <Translate path={`directus.page_about_plus.princples_list.title_${index + 1}`} />
                  </Heading>
                  <Space size="s" />
                  <BodyText>
                    <Translate path={`directus.page_about_plus.princples_list.description_${index + 1}`} />
                  </BodyText>
                  <Space size="s" />
                  <PrinciplesLink path={principle.link_url}>
                    <Translate path={`directus.page_about_plus.princples_list.link_label_${index + 1}`} />
                  </PrinciplesLink>
                </Box>
              </PrinciplesGridItem>
            ))}
          </PrinciplesGrid>
        </Container>
      </PrinciplesSection>
    </>
  );
};
export default IndexPage;
